import SwiperCarousel from '../../libs/swiper/swiper-bundle.mjs';

class Swiper {
	constructor(carouselHTMLElement, config) {
		addSwiperDecoration(carouselHTMLElement, config.buildingCarouselCallback);
		const swiper = new SwiperCarousel(carouselHTMLElement, config);

		swiper.custom = {};

		swiper.custom.carouselHTMLElement = carouselHTMLElement;
		swiper.custom.config = config;

		return swiper;
	}
}
/**
 * Adds Swiper Decoration for carousel
 * @param {Object} carouselHTMLElement Carousel HTML Element
 */
function addSwiperDecoration(carouselHTMLElement, buildingCarouselCallback) {
	carouselHTMLElement.classList.add('swiper');
	carouselHTMLElement.innerHTML = '<div class="swiper-wrapper js-carousel-wrapper">'
		+ carouselHTMLElement.innerHTML
		+ '</div>';

	const carouselSlides = carouselHTMLElement.querySelectorAll('.js-carousel-slide');

	/* eslint-disable-next-line */
	for (const carouselSlide of carouselSlides) {
		carouselSlide.classList.add('swiper-slide');
	}

	if (buildingCarouselCallback) {
		buildingCarouselCallback(carouselHTMLElement);
	}
}

export default Swiper;
